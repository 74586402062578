<template>
  <div>
    <Nav/>
    <section class="hero">
      <div class="hero__text">
        <h1>Hi, I'm Kara Luton.</h1>
        <span>UI Engineer + Speaker</span>
      </div>
      <div class="hero__photo">
        <img src="../assets/maggie-and-kara.jpeg" alt="Kara with her dog, Maggie, sitting beside her on a chair both looking down at a laptop.">
      </div>
    </section>
  </div>
</template>

<script>
import Nav from './Nav.vue';
export default {
  name: 'Hero',
  components: {
    Nav
  }
}
</script>

<style lang="scss">
  .hero {
    color: $peach;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50vh;
      margin: 0 auto;
      width: 203px;
    }

    &__photo {
      align-items: center;
      background: $orange;
      display: flex;
      height: 50vh;
      justify-content: center;

      img {
        aspect-ratio: 2 / 3;
        width: 200px;
        object-fit: cover;
      }
    }
  }

  @media (min-width: 760px) {
    .hero {
      &__text {
        width: 259px;
      }
    }
  }

  @media (min-width: 1024px) {
    .hero {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      height: 100vh;

      &__text {
        height: inherit;
        margin: unset;
        padding-left: 90px;
      }

      &__photo {
        height: inherit;

        img {
          width: 400px;
        }
      }
    }
  }
</style>

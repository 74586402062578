<template>
  <section id="about" class="about">
    <h2 class="about__title">About Me</h2>
    <p class="about__bio">
      Kara went from tutus to tech. After pursuing a career as a professional ballerina, Kara moved
      back to her hometown of Nashville, TN to work as a music publicist. A life of pitching journalists
      and walking red carpets left her feeling unfulfilled and she made the switch to being a software engineer.
      Now, Kara has a passion for introducing others to this industry and showing them they can succeed.
      <br><br>
      Outside of work, Kara loves to take a million photos of her dogs - Maggie and Franklin - as well as
      go to concerts and see a ballet whenever she can.
    </p>
    <div class="about__links">
      <ul class="about__group">
        <h2 class="about__title">Talks</h2>
        <!-- <h3 class="about__subtitle">Upcoming:</h3> -->
        <p>Interested in having me speak at your conference or for your company? Contact me <a href="#contact">here!</a></p>
        <h3 class="about__subtitle">Past:</h3>
        <li class="about__item">Techorama NL - 2022</li>
        <li class="about__item">DevSum - 2022</li>
        <li class="about__item"><a href="https://www.youtube.com/watch?v=7JzoBM-dAAw" target="_blank">EmberConf - 2022</a></li>
        <li class="about__item">Kansas City Developer Conference - 2021 </li>
        <li class="about__item">Code PaLOUsa - 2020</li>
        <li class="about__item"><a href="https://www.youtube.com/watch?v=PYZzMEfu2mo&feature=youtu.be" target="_blank">EmberConf - 2020</a></li>
        <li class="about__item">Music City Tech - 2019</li>
        <li class="about__item">Underground JS - 2019 (Keynote Speaker)</li>
        <li class="about__item">CodeStock - 2019</li>
      </ul>
      <ul class="about__group">
        <h2 class="about__title">Press</h2>
        <li class="about__item"><a href="https://www.whiskeywebandwhatnot.fm/transitioning-to-tech-and-writing-what-you-know-with-kara-luton/" target="_blank">Whiskey, Web and Whatnot Podcast</a></li>
        <li class="about__item"><a href="https://community.codenewbie.org/codenewbiepodcast/s17-e3-how-to-do-remote-work-well-kara-luton" target="_blank">Code Newbie Podcast</a></li>
        <li class="about__item"><a href="https://learntocodewith.me/podcast/tutus-to-tech-ballerina-to-front-end-developer-kara-luton/" target="_blank">Learn To Code With Me Podcast</a></li>
        <li class="about__item"><a href="https://www.timbourguignon.fr/devjourney158/" target="_blank">Dev Journey Podcast</a></li>
        <li class="about__item"><a href="https://anchor.fm/devtheory/episodes/Episode-6---Interview-With-Kara-Luton-e10aa12" target="_blank">Dev Theory Podcast</a></li>
        <li class="about__item"><a href="https://www.nocsdegree.com/from-tutus-to-tech-kara-luton-is-a-software-engineer-with-no-cs-degree/" target="_blank">No CS Degree</a></li>
        <li class="about__item"><a href="https://www.cfpland.com/blog/speakers-story-kara-luton/" target="_blank">CFP Land</a></li>
        <li class="about__item"><a href="https://womeninwebdev.com/blog/kara-luton-female-developer/" target="_blank">Women In Web Dev</a></li>
        <li class="about__item"><a href="https://www.youtube.com/watch?v=W6USqnAdOkQ&t=4749s" target="_blank">Jeffrey Fritz Twitch Stream</a></li>
        <li class="about__item"><a href="https://www.businessinsider.com/work-from-home-tips-health-2019-4" target="_blank">Business Insider</a></li>
        <li class="about__item"><a href="https://www.womenoftech.co.uk/post/kara-front-end-developer" target="_blank">Women of Tech</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style lang="scss">
  .about {
    background: $peach;
    color: $cream;
    padding: 50px;

    &__links {
      margin-top: 50px;
    }

    &__group {
      margin: 0;
      list-style-position: inside;

      p {
        font-size: 14px;
      }

       a {
        color: $cream;
      }

      &:last-of-type {
        margin-top: 30px;
      }
    }

    &__title {
      margin-bottom: 15px;
    }

    &__subtitle {
      margin: 0;
    }

    &__item {
      a {
        color: $cream;
      }
    }
  }

  @media (min-width: 760px) {
    .about {
      &__links {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
      }

      &__group {
        &:last-of-type {
          margin-top: 0;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .about {
      padding: 90px;
    }
  }
</style>

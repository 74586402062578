<template>
  <main>
    <Hero/>
    <About/>
    <Contact/>
  </main>
</template>

<script>
import Hero from './Hero';
import About from './About';
import Contact from './Contact';

export default {
  name: 'Home',
  components: {
    Hero,
    About,
    Contact,
  }
}
</script>
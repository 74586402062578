import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import Home from '@/components/Home.vue';
import Press from '@/components/Press.vue';

Vue.config.productionTip = false

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/press-kit',
      name: 'Press',
      component: Press,
    },
  ],
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')

<template>
<div class="page">
    <a class="home" href="/">Home</a>
  <main class="press-kit">
    <h1 class="press-kit__title">Press Kit</h1>
    <p class="press-kit__subtitle">The following information is provided to be used as-is for event organizers and media publishers.</p>
    <h2 class="press-kit__section">Basic Information</h2>
    <table class="press-kit__table">
      <tbody>
        <tr>
          <td>Name</td>
          <td>Kara Luton <br> Pronounced CARE-uh LOO-tuhn</td>
        </tr>
        <tr>
          <td>Pronouns</td>
          <td>she/her</td>
        </tr>
        <tr>
          <td>Job Title</td>
          <td>UI Engineer at <a class="press-kit__link" href="https://www.crowdstrike.com/">CrowdStrike</a></td>
        </tr>
        <tr>
          <td>Where I'm From</td>
          <td>Nashville, TN, USA</td>
        </tr>
        <tr>
          <td>Twitter</td>
          <td><a class="press-kit__link" href="https://www.twitter.com/karaluton">@karaluton</a></td>
        </tr>
        <tr>
          <td>LinkedIn</td>
          <td><a class="press-kit__link" href="https://linkedin.com/in/karaluton">linkedin.com/in/karaluton</a></td>
        </tr>
        <tr>
          <td>Website</td>
          <td><a class="press-kit__link" href="https://www.karaluton.com">karaluton.com</a></td>
        </tr>
      </tbody>
    </table>
    <section class="press-kit__section">
      <h2 class="press-kit__header">Bios</h2>
      <h3 class="press-kit__subheader">Short</h3>
      <p>Kara Luton went from wearing tutus to working as a software engineer.</p>
      <h3 class="press-kit__subheader">Medium</h3>
      <p>After pursuing a career as a professional ballerina and working as a music publicist, Kara Luton made the switch to being a software engineer. Now, Kara has a passion for introducing others to this industry and showing them they can succeed.</p>
      <h3 class="press-kit__subheader">Long(ish)</h3>
      <p> Kara went from tutus to tech. After pursuing a career as a professional ballerina, Kara moved
      back to her hometown of Nashville, TN to work as a music publicist. A life of pitching journalists
      and walking red carpets left her feeling unfulfilled and she made the switch to being a software engineer.
      Now, Kara has a passion for introducing others to this industry and showing them they can succeed.
      <br><br>
      Outside of work, Kara loves to take a million photos of her dogs - Maggie and Franklin - as well as
      go to concerts and see a ballet whenever she can.</p>
    </section>
    <!-- <section class="press-kit__section">
      <h2 class="press-kit__header press-kit__header--purple">Praise</h2>
      <p>"EmberConf is about the overlap between community and technology. Kara Luton's talk right now highlights how non-stereotypical backgrounds are an asset. The most focused and diligent people I've worked with were artists who became software engineers."" <br><br> - <a href="https://twitter.com/endangeredmassa/status/1240034914692718592?s=20">Sean Massa</a></p>
    </section> -->
    <section class="press-kit__section">
      <h2 class="press-kit__header">Photos</h2>
      <div class="press-kit__grid">
        <img src="../assets/kara-headshot-2.jpg" loading="lazy" class="press-kit__photo" alt="Kara looking at the camera wearing a white blouse and a maroon background.">
        <img src="../assets/kara-headshot.jpeg" loading="lazy" class="press-kit__photo" alt="Kara looking at the camera wearing a white blouse. Blurred plants in the background.">
        <img src="../assets/kcdc.jpg" loading="lazy" class="press-kit__photo" alt="Kara standing at a podium giving a talk at the KCDC conference with attendees in the foreground.">
        <img src="../assets/maggie-and-kara.jpeg" loading="lazy" class="press-kit__photo" alt="Kara sitting on a chair on a balcony with a laptop in her lap. Kara's dog is also in the chair and they are both looking inquisitively at the laptop.">
        <img src="../assets/kara-informal.jpeg" loading="lazy" class="press-kit__photo press-kit__photo--span-2" alt="Kara looking at the camera wearing a cream-colored sweater with a laptop in front of her and a recreation room in the background.">
      </div>
    </section>
  </main>
  </div>
</template>

<script>
export default {
  name: 'Press',
}
</script>

<style lang="scss">
  .home {
    color: $cream;
    font-family: 'Source Serif Pro', serif;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 25px 50px;
    position: absolute;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .press-kit {
    background: $peach;
    color: $cream;
    padding: 50px;

    &__link {
      color: $cream;
    }

    &__title {
      margin-top: 25px;
    }

    &__table {
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 14px;
      width: 100%;

       tbody {
        tr {
          border-bottom: 1px dashed $cream;
          border-left: 0;
          border-right: 0;

          &:last-child {
            border-bottom: 0;
          }
        }

        td {
          padding: 0.75rem 0.45rem;

          &:first-child {
            width: 25%;
          }
        }
      }
    }

    &__section {
      border-top: 1px dashed $cream;
      margin-top: 40px;
      padding-top: 40px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__subheader {
      text-decoration: underline;
    }

    &__grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr;
      grid-template-rows: 350px;
      grid-auto-rows: 350px;
    }

    &__photo {
      background: $cream;
      cursor: pointer;
      height: 100%;
      object-fit: cover;
      width: 100%;

      &:hover {
        object-fit: contain;
      }
    }
  }

  @media (min-width: 768px) {
    .press-kit {
      &__grid {
        grid-template-columns: repeat(2, 1fr);
      }

      &__photo--span-2 {
        grid-column: span 2;
      }
    }
  }

  @media (min-width: 1024px) {
    .home {
      padding: 25px 90px;
    }

    .press-kit {
      padding: 90px;
    }
  }
</style>
<template>
  <header class="nav">
    <ul class="nav__links">
      <li><a href="#about">About</a></li>
      <li><router-link :to="{ name: 'Press' }">Press Kit</router-link></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
  </header>
</template>

<script>
export default {
  name: 'Nav',
}
</script>

<style lang="scss">
  .nav {
    font-family: 'Source Serif Pro', serif;
    font-size: 15px;

    &__links {
      display: flex;
      justify-content: space-around;
      list-style: none;

      a {
        color: $peach;
        text-decoration: none;
        text-transform: uppercase;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .nav {
      padding-left: 50px;
    }
  }

  @media (min-width: 1024px) {
    .nav {
      width: calc(50vw - 100px);
      padding-left: 90px;
      position: absolute;

      &__links {
        justify-content: flex-start;
        gap: 50px;
        margin: 0;
        padding: 25px 0;
      }
    }
  }
</style>

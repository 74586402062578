<template>
  <section id="contact" class="contact">
    <div>
      <h2 class="contact__title">Contact</h2>
      <form ref="form" class="contact__form" name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" @submit.prevent="handleSubmit">
        <input type="hidden" name="form-name" value="contact" />
        <div class="contact__input contact__input--name">
          <label for="name">Name:</label>
          <input type="text" name="name" id="name" @input="ev => form.name = ev.target.value" required/>
        </div>
        <div class="contact__input contact__input--email">
          <label for="email">Email:</label>
          <input type="email" name="email" id="email" @input="ev => form.email = ev.target.value" required/>
        </div>
        <div class="contact__input contact__input--message">
          <label for="message">Message:</label>
          <textarea name="message" id="message" @input="ev => form.message = ev.target.value" required></textarea>
        </div>
        <button class="contact__submit">Submit</button>
      </form>
      <p v-show="success" class="contact__text">Success! Thanks for reaching out. I'll get back with you soon.</p>
      <p v-show="fail" class="contact__text">Oops! Something went wrong.</p>
    </div>
    <img src="../assets/developer.png" class="contact__image" alt="Cartoon character of a redheaded developer sitting at a desk with her back facing you and looking at a computer screen. Credit: pikisuperstar on freepik.com">
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Contact',
  data() {
      return {
        form: {
          name: '',
          email: '',
          message: '',
        },
        success: false,
        fail: false,
      }
    },
    methods: {
      encode(data) {
        return Object.keys(data)
          .map(
            key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
      },
      handleSubmit() {
        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
        };

        axios.post(
          "/",
          this.encode({
            "form-name": "contact",
            ...this.form
          }),
          axiosConfig
        ).then(() => {
          this.success = true;
          this.$refs.form.reset();
        })
        .catch(() => {
          this.fail = true;
        })
      }
    }
}
</script>

<style lang="scss">
  .contact {
    color: $peach;
    padding: 50px;

    &__title {
      margin-bottom: 15px;
    }

    &__form {
      font-family: 'DM Sans', sans-serif;

      input, textarea {
        font-family: inherit;
        background: $cream;
        border: 1px solid $peach;
        outline: none;
      }
    }

    &__input {
      font-size: 18px;
      display: flex;
      flex-direction: column;
      margin: 10px 0;
    }

    &__submit {
      background: $peach;
      border: none;
      cursor: pointer;
      color: $cream;
      height: 25px;
      text-transform: uppercase;
      margin-top: 15px;
      min-width: 100%;
    }

    &__text {
      font-size: 12px;
    }

    &__image {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .contact {
      &__form {
        display: grid;
        grid-column-gap: 30px;
        grid-template-areas:
          "name     email"
          "message  message"
          "submit   submit";
      }

      &__input--name {
        grid-area: name;
      }

      &__input--email {
        grid-area: email;
      }

      &__input--message {
        grid-area: message;
      }

      &__submit {
        grid-area: submit;
      }
    }
  }

  @media (min-width: 1024px) {
    .contact {
      align-items: center;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 90px;

      &__form {
        max-width: 550px;
      }

      &__image {
        display: block;
        justify-self: end;
        width: 400px;
      }
    }
  }
</style>
